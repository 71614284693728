import PrivateNetworkClient from "helpers/privateNetwork";


const ProfileDetails = async () => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get("/profileDetails");
  return data;
};

const UpdateProfileDetails = async (formData) => {
  const { data } = await PrivateNetworkClient.put("/editProfile", formData);
  return data;
}

export {
  ProfileDetails,
  UpdateProfileDetails
};
