import React, { lazy, Suspense, useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useProfileDetails } from "hooks/profile/profile";

// Layouts
import AuthLayout from "layouts/AuthLayout";
import AppLayout from "layouts/AppLayout";
import Dwell from "views/app/reports/dwell";
import HoursWorked from "views/app/reports/workHour";
import globalRouter from "globalRouter.ts";

// Authentication Routes
const SignIn = lazy(() => import("./views/auth/login"));
const ForgotPassword = lazy(() => import("./views/auth/forgotPassword/index"));
const SetPassword = lazy(() => import("./views/auth/forgotPassword/setPassword"));

// Admin Routes
const Dashboard = lazy(() => import("./views/app/dashboard"));
const SafeReport = lazy(() => import("./views/app/reports/safeReport"));
const TodayAttendance = lazy(() => import("./views/app/reports/todayAttendance"));
const FirstInLastOut = lazy(() => import("./views/app/reports/firstInLastOut"));
const SearchEmployee = lazy(() => import("./views/app/reports/searchEmployee"));
const UnAuthorized = lazy(() => import("./views/app/reports/unAuthorized"));
const TodayAbsentees = lazy(() => import("./views/app/reports/todayAbsentees"));
const EmergencyRollcall = lazy(() => import("./views/app/reports/emergencyRollcall"));

const Alerts = lazy(() => import("./views/app/configuration/alerts"));
const Department = lazy(() => import("./views/app/configuration/department"));
const DepartmentForm = lazy(() => import("./views/app/configuration/department/form"));
const Employee = lazy(() => import("./views/app/configuration/employee"));
const EmployeeForm = lazy(() => import("./views/app/configuration/employee/form"));
const GateWay = lazy(() => import("./views/app/configuration/gateway"));
const GateWayForm = lazy(() => import("./views/app/configuration/gateway/form"));
const FloorPlan = lazy(() => import("./views/app/configuration/floorplan"));
const FloorPlanForm = lazy(() => import("./views/app/configuration/floorplan/form"));
const FloorPlanZoneDetails = lazy(() => import("./views/app/configuration/floorplan/floorZoneList/zoneListForFloor"));
const FloorPlanZoneEdit = lazy(() => import("./views/app/configuration/floorplan/floorZoneList/editZoneListForFloor"));
const FloorPlanMap = lazy(() => import("./views/app/configuration/floorplan/gatewayFloorMap/gatewayMap"));
const Beacon = lazy(() => import("./views/app/configuration/becon"));
const BeaconForm = lazy(() => import("./views/app/configuration/becon/form"));
const Zone = lazy(() => import("./views/app/configuration/zone"));
const ZoneForm = lazy(() => import("./views/app/configuration/zone/form"));
const Admin = lazy(() => import("./views/app/admin/admin"));
const AdminForm = lazy(() => import("./views/app/admin/admin/form"));
const Roles = lazy(() => import("./views/app/admin/roles"));
const RolesForm = lazy(() => import("./views/app/admin/roles/form"));
const Profile = lazy(() => import("./views/app/profile"));

function App() {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;
  const { data: profileData, refetch: initialRefetch } = useProfileDetails();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (profileData) {
      setData(profileData?.data?.resDto)
    }
  }, [profileData])

  return (
    <Suspense fallback={<div className="loader-img"><h1>Loading.....</h1></div>}>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route exact path="/" element={<SignIn />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/setPassword" element={<SetPassword />} />
        </Route>
        <Route element={<AppLayout profileData={profileData} initialRefetch={initialRefetch} />}>
          <Route path="/" element={<Dashboard hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/dashboard" element={<Dashboard hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/reports" element={<Navigate to="/reports/today-attendance" />} />
          <Route path="/reports/safe-report" element={<SafeReport />} />
          <Route path="/reports/today-attendance" element={<TodayAttendance />} />
          <Route path="/reports/first-in-last-out" element={<FirstInLastOut />} />
          <Route path="/reports/search-employee" element={<SearchEmployee />} />
          <Route path="/reports/un-authorized" element={<UnAuthorized />} />
          <Route path="/reports/dwell" element={<Dwell />} />
          <Route path="/reports/hours-worked" element={<HoursWorked />} />
          <Route path="/reports/today-absentees" element={<TodayAbsentees />} />
          <Route path="/reports/emergency-roll-call" element={<EmergencyRollcall />} />
          <Route path="/configuration" element={<Navigate to="/configuration/employee" />} />
          <Route path="/configuration/alerts" element={<Alerts hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/configuration/department" element={<Department hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/configuration/department/:id" element={<DepartmentForm />} />
          <Route path="/configuration/employee" element={<Employee hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/configuration/employee/:id" element={<EmployeeForm />} />
          <Route path="/configuration/gateway" element={<GateWay hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/configuration/gateway/:id" element={<GateWayForm />} />
          <Route path="/configuration/floorplan" element={<FloorPlan hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/configuration/floorplan/:id" element={<FloorPlanForm />} />
          <Route path="/configuration/floorplan/zonedetails" element={<FloorPlanZoneDetails hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/configuration/floorplan/zonedetails/:id" element={<FloorPlanZoneEdit />} />
          <Route path="/configuration/floorplan/gatewayfloormap/:id/:siteName/:floorName" element={<FloorPlanMap />} />
          <Route path="/configuration/beacon" element={<Beacon hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/configuration/beacon/:id" element={<BeaconForm />} />
          <Route path="/configuration/zone" element={<Zone hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/configuration/zone/:id" element={<ZoneForm />} />
          <Route path="/admin" element={<Admin hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/admin/:id" element={<AdminForm />} />
          <Route path="/admin/roles" element={<Roles hide={data} initialRefetch={initialRefetch} />} />
          <Route path="/admin/roles/:id" element={<RolesForm />} />
          <Route path="/profile" element={<Profile hide={data} initialRefetch={initialRefetch} />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
