import PrivateNetworkClient from "helpers/privateNetwork";

const DownloadCSV = async (uri,searchKey, page, size) => {
    const { data } = await PrivateNetworkClient.get(`/${uri}?searchKey=${searchKey}&page=${page}&size=${size}`);
    return data;
}

const PostDownload = async (uri, formData) => {
    const { data } = await PrivateNetworkClient.post(`/${uri}`, formData);
    return data;
}



export {
    DownloadCSV,
    PostDownload
}