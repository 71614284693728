import PrivateNetworkClient from "helpers/privateNetwork";

const SearchEmployeeList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.post("/reports/searchEmployee", formData);
  return data;
}

const TodaysAttendenceList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.post("/attendance", formData);
  return data;
}

const FirstInLastOutList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.post('/filo', formData);
  return data;
}

const UnAuthorisedEmployeeList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.post('/reports/unAuthorized', formData);
  return data;
}

const dashboardAttendenceList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.post('/attendance/v1', formData);
  return data;
}

const DwellEmployeeList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.post('/dwellReport', formData);
  return data;
}

const HoursWorkedEmployeeList = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.post('/hoursWorked', formData);
  return data;
}

const EmployeeDropdownList = async (id) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get(`/employee/dropdown?departmentId=${id || ""}`);
  return data;
}

export {
  SearchEmployeeList,
  TodaysAttendenceList,
  FirstInLastOutList,
  UnAuthorisedEmployeeList,
  DwellEmployeeList,
  HoursWorkedEmployeeList,
  EmployeeDropdownList,
  dashboardAttendenceList
}